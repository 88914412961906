.box_container {
  top: 100px;
  left: 10px;
  position: absolute;
  z-index: 200;
  &__heatmap {
    top: 154px;
    left: 10px;
    position: absolute;
    z-index: 200;
  }
}

.box {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 8px;
  margin-left: 4px;
}
.legend {
  color: black;
  font-weight: bold;
  overflow: visible;
  width: 15px;
  height: 15px;
  border-radius: 5px;
}
.color_label {
  overflow: visible;
  text-wrap: nowrap;
  padding-left: 20px;
}

.image_legend_div {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
}
.blue_marker_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.red_marker_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
}

.closeIconDiv {
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  margin-top: 20px;
  cursor: pointer;
}
