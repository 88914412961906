.wrap-text {
    overflow: visible; 
    min-height: 50px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    /* margin: 8px 0px 8px 0px; */
  }
  

  .row-styles{
    padding: 0px 0px 10px 0px; 
  }

  .wrap-header {
    white-space: normal; 
    word-wrap: break-word; 
    text-align: center; 
  }

  