.navbar_container {
  display: flex;
  align-items: center;
  height: 8vh;
  justify-content: space-between;
}

.menu_icon {
  cursor: pointer;
  width: 30;
  height: 30;
  padding: 2px 8px;
}

.drawer_list_item {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 20px 0px;
  cursor: pointer;
}

.button_container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
