.main_container {
  display: flex;
  flex-direction: column;
  height: 92vh;
  width: 100vw;
}

.map_container {
  height: 100%;
  width: 100%;
  position: relative;
}

.map {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.layerListContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 10px;
  top: 72px;
  padding: 5px;
  left: 10px;
  box-shadow: 3px;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 200;
}

.checkbox {
  padding: 0px;
  height: 30px;
}

.typography {
  font-weight: 500;
  color: black;
  &__grayText {
    font-weight: 500;
    color: gray;
  }
}

.layerList {
  width: 170px;
  padding: 6px 8px;
}

.projectList {
  min-width: 170px;
  max-height: 300px;
  padding: 4px 4px;
  margin: auto;
}
.projectListButtonStack {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: space-between; /* Removed quotes */
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  top: 170px;
  left: 10px;
  overflow: auto;
  opacity: 0.9;
  z-index: 1;
}

.listContainer::-webkit-scrollbar-thumb {
  background-color: rgba(154, 154, 154, 0.85);
  border-radius: 20px;
}

.listContainer::-webkit-scrollbar {
  width: 10px;
}

.listContainer::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.projectsDrawer {
  width: 55vw;
  margin: 16px;
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.cursorPointer {
  cursor: pointer;
}
