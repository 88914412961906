.main_container {
  display: flex;
  flex-direction: column;
  height: 92vh;
  width: 100vw;
}

.map_container {
  height: 100%;
  width: 100%;
  position: relative;
}

.map {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}
